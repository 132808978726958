import React from "react"
import { formatDate, navigateNoRefresh } from "../services/helper"
import moment from "moment"
import { Link } from "@reach/router"

export const CurrentEventView = ({ event }) => {
  return (
    <>
      {event && (
        <>
          <div>
            Du {formatDate(event.start)} au {formatDate(event.end)}
          </div>
          <div>Nombre de personnes max : {event.max}</div>
          <div>Prix : {event.price}€</div>
          <div>Type : {event.type}</div>
          <div>
            Utilisateurs :
            <ul>
              {event.prestationUsers &&
                event.prestationUsers.map(pu => {
                  return <li key={pu.user.id}><a className="link" href={"/app/users/"+pu.user.id}>{pu.user.name}</a></li>
                })}
            </ul>
          </div>
        </>
      )}
    </>
  )
}

export const ListEventsDay = ({ day, list, onItemClick }) => {
  
  return (
    <>
      <h2>{moment(day).format("DD MMMM YYYY")}</h2>

      {list && list.map(event => (
        <div key={event.id}>
          <div className="link" onClick={() => {onItemClick(event); navigateNoRefresh("/app/calendar/" + event.id)}}>
            <span className="small">
              De {moment(event.start).format("HH:mm")} à{" "}
              {moment(event.end).format("HH:mm")}
            </span>
            <span className="ml-3">{event.type}</span>
          </div>
          <div className="ml-5">
            {event.prestationUsers &&
              event.prestationUsers.map(pu => (
                <span key={pu.user.id}>{pu.user.name}</span>
              ))}
          </div>
        </div>
      ))}
    </>
  )
}
