import React, { Component } from "react"
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import "moment/locale/fr"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { getPrestations } from "../../services/prestations"
import { Modal } from "react-bootstrap"
import { ListEventsDay, CurrentEventView } from "../../views/eventsView"
import { navigate } from "@reach/router"
import { navigateNoRefresh, formatDateUrl } from "../../services/helper"

const localizer = momentLocalizer(moment)

const messages = {
  // new
  allDay: "Jour",
  previous: "<",
  next: ">",
  today: "Aujourd'hui",
  month: "Mois",
  week: "Semaine",
  day: "Jour",
  agenda: "Agenda",
  date: "Date",
  time: "Temps",
  event: "Évènement",
}

export default class Calendar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      events: [],
      openModal: false, 
      currentEvent: null, 
      currentDay: null
    }
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.refresh()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  refresh = () => {
    getPrestations()
      .then(items => {
        const formattedArray = []
        items.forEach(presta => {
          var title =
            presta.type === "Cours particulier" &&
            presta.prestationUsers &&
            presta.prestationUsers.length > 0
              ? presta.prestationUsers[0].user.name
              : presta.type
          if (presta.isRecurring) {
            const prestaStart = presta.start.split(":")
            const prestaEnd = presta.end.split(":")

            const dates = presta.datePrestation.map(date => {
              const start = moment(date)
              const end = moment(date)
              start.set({ hour: prestaStart[0], minute: prestaStart[1] })
              end.set({ hour: prestaEnd[0], minute: prestaEnd[1] })
              return {
                ...presta,
                start: new Date(start.toDate()),
                end: new Date(end.toDate()),
                title: title,
              }
            })
            dates.forEach(date => {
              formattedArray.push(date)
            })
          } else {
            formattedArray.push({
              ...presta,
              start: new Date(moment(presta.start).toDate()),
              end: new Date(moment(presta.end).toDate()),
              title: title,
            })
          }
        })

        var state = { events: formattedArray }
        if (this.props.id) {
          state.openModal = true
          state.currentDay = null
          state.currentEvent = formattedArray.find(e => e.ed == this.props.id)
          console.log(state.currentEvent)
        } else if (this.props.day) {
          state.openModal = true
          state.currentEvent = null
          state.currentDay = moment(this.props.day, "DD-MM-yyyy")
        } else {
          state.openModal = false
          state.currentEvent = null
          state.currentDay = null
        }
        if (this._isMounted) {
          this.setState(state)
        }
      })
      .catch(e => console.error(e))
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: event.color,
        opacity: 0.8,
        display: "block",
      },
    }
  }
  onEventSelect = eventSelected => {
    navigateNoRefresh("/app/calendar/" + eventSelected.id)
    this.setState({ openModal: true, currentEvent: eventSelected })
  }
  onSlotSelect = ({ start }) => {
    this.setState({
      openModal: true,
      currentEvent: null,
      currentDay: start,
    })
    navigateNoRefresh("/app/calendar/list/" + formatDateUrl(start))
  }
  onHideModal = () => {
    this.setState({
      openModal: false,
      currentEvent: null,
      currentDay: null,
    })
    navigateNoRefresh("/app/calendar")
  }
  getPrestationsAtDate = date => {
    return this.state.events.filter(event => {
      return (
        moment(date).isBetween(moment(event.start), moment(event.end)) ||
        moment(date).isSame(moment(event.start), "day") ||
        moment(date).isSame(moment(event.end), "day")
      )
    })
  }

  render() {
    const { openModal, currentEvent, currentDay } = this.state

    return (
      <div id="app-calendar">
        <Modal show={openModal} onHide={() => this.onHideModal()}>
          <Modal.Header closeButton>
            <Modal.Title>Détail du cours</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentEvent ? (
              <CurrentEventView event={currentEvent} />
            ) : (
              currentDay && (
                <ListEventsDay
                  day={currentDay}
                  list={this.getPrestationsAtDate(currentDay)}
                  onItemClick={event => this.setState({ currentEvent: event })}
                />
              )
            )}
          </Modal.Body>
        </Modal>

        <BigCalendar
          defaultDate={new Date()}
          defaultView="month"
          culture="fr"
          messages={messages}
          localizer={localizer}
          events={this.state.events}
          onSelectSlot={this.onSlotSelect}
          onSelectEvent={event => this.onEventSelect(event)}
          resizable
          selectable
          min={new Date(0, 0, 0, 6, 0, 0)}
          max={new Date(0, 0, 0, 22, 0, 0)}
          eventPropGetter={this.eventStyleGetter}
          style={{ height: "100%" }}
        />
      </div>
    )
  }
}
