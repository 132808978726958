import { get, post } from "./fetch";

export const getPrestations = () => new Promise((resolve, reject) => {
  get("prestations").then(data => {
    resolve(data);
  }).catch(error => {
    reject(error);
  })
});

export const getPrestation = (id) => new Promise((resolve, reject) => {
  get("prestations/"+id).then(data => {
    resolve(data);
  }).catch(error => {
    reject(error);
  })
});